/* Site Fonts */
@font-face {
  font-family: "Nov-Medium";
  src: url("./assets/fonts/Nov-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Nov-MediumItalic";
  src: url("./assets/fonts/Nov-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Nov-Regular";
  src: url("./assets/fonts/Nov-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Nov-SemiLightItalic";
  src: url("./assets/fonts/Nov-SemiLightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "TitleBroadOutlineLarge";
  src: url("./assets/fonts/TitleBroadOutlineLarge.ttf") format("truetype");
}




/* Global App Parameters */
.App {
  min-height: 100vh;
  min-height: 100svh;
  width: 100vw;
  display: flex;
  justify-content: center;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.fade-in {
  opacity: 0;
  transition: opacity 0.2s ease-in;
}

.fade-in.active {
  opacity: 1;
}

.App-Background {
  position: fixed; /* Fix the background gradient */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Place the background behind the content */
  background-image: linear-gradient(35deg, #c45F33, #512B2B);
}

.Page-Content-Container-1 {
  max-width: 1100px;
  margin-top: 6vh;
  margin-bottom: 6vh;
  margin-left: 2vw;
  margin-right: 2vw;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  flex-direction: column;
}

.Page-Content-Container-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.Page-Content-Container-3 {
  display: flex;
  flex-direction: column;
}

.Buttonized {
  user-select: none;
  cursor: default;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  text-decoration: none;
  color: #FFFFFF;
}

.Buttonized:hover {
  opacity: 0.5;
}

.content {
  overflow: hidden;
  max-height: 0;
  transition: all 0.3s ease-out;
}

.content.open {
  max-height: 2000px; /* or some other large value */
}

@media (min-width: 900px) {
  .Page-Content-Container-1 {
    margin-left: 3vw;
    margin-right: 3vw;
  }
}




/* Global Spacers */
.Spacer-4X {
  display: flex;
  height: 100px
}

.Spacer-3X {
  display: flex;
  height: 75px
}

.Spacer-2X {
  display: flex;
  height: 50px
}

.Spacer-1X {
  display: flex;
  height: 25px
}




/* Global Text Parameters */
h1 {
  font-family: TitleBroadOutlineLarge;
  color: #FFFFFF;
  font-size: 100px;
  line-height: 80px;
  font-weight: 100;
  margin-top: 0;
  margin-bottom: 0;
  user-select: none;
}

h2 {
  font-family: Nov-Medium;
  font-size: 26px;
  color: #FFFFFF;
  margin: 0;
}

h2.italic {
  font-family: Nov-MediumItalic;
}

h3 {
  font-family: Nov-Medium;
  color: #FFFFFF;
  margin: 0;
}

p {
  font-family: Nov-Regular;
  color: #FFFFFF;
  margin: 0;
}

p.large {
  font-size: 20px;
}

p.italic {
  font-family: Nov-SemiLightItalic;
}


/* Landing Page Parameters */
.Avatar-Image {
  border-radius: 50%;
  height: 160px;
}

.Avatar-Right {
  justify-content: right;
}

.Subheader-Container {
  display: flex;
  flex-direction: column;
}

.Subheader-Container p {
  max-width: 440px;
}

.Contact-Container {
  display: flex;
}

.Contact-Icon {
  color: #FFFFFF;
  height: 20px;
  margin-right: 14px;
}

.Contact-Icon-Custom {
  color: #FFFFFF;
  height: 28px;
  margin-right: 14px;
}




/* Breakpoint Adjustments */
@media (max-width: 500px) {
  h1 {
    font-size: 80px;
    line-height: 60px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 16px;
  }

  p {
    font-size: 14px;
  }

  p.large {
    font-size: 16px;
  }
}
